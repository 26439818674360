import { Charity } from './charity.model';
import { Currency } from './user';

export class Fund {
  cseg5: string;
  fundName: string;
  fundType: string;
  fundNumber: string;
  charityStatus?: string;
  location?: string;
  nextRenewDate?: string;
  currency: string;
  currencySymbol: string;
  receiveGrantCurrency: string;
  receiveGrantCurrencySymbol: string;
  contactRoleAccessLevel?: string;
  closingBalance: number;
  availableAmount: number;
  primaryCurrencyId?: string;
  primaryCurrencySymbol?: string;
  showConverter?: boolean;
  hasOtherCurrencies?: boolean;
  preferredName: string;
  hasFundCash: boolean;
  projects: Fund[];
  parentFund: Charity;
  contactRole: UserContactRole;
  name?: string;

  constructor() {}
}

export type UserContactRole = 'Fund Holder 1' | 'Primary Contact - Fund Holder 1' | string;

export interface CurrencyAndBalance extends Currency {
  balance: number;
  hasFundCash: boolean;
  availableAmountFundCash: number;
}

export interface ActivityData {
  mainAssetSummary: MainAssetSummary;
  otherAssets: OtherAsset[];
  transactions: Transaction[];
}

export class OtherAsset {
  constructor(public internalId: string, public assetName: string, public fundCash: boolean) {}
}

export interface Transaction {
  internalId: string;
  description: string;
  location: string;
  amount: number;
  submittedOn: string;
  completedOn: string;
  submittedOnString: string;
  completedOnString: string;
  tabType: string;
  statementNote: string;
}

export interface PendingTransaction {
  internalId: string;
  receiverFundName: string;
  location: string;
  amount: number;
  submittedOn: string;
  requestedFor: string;
  submittedOnString: string;
  requestedForString: string;
  transactionType: string;
}

export class TransactionData {
  all: Transaction[];
  contributions: Transaction[];
  grants: Transaction[];
  transfers: Transaction[];
  otherActivities: Transaction[];
  pending: any;

  constructor() {}

  resetFields() {
    this.all = null;
    this.contributions = null;
    this.grants = null;
    this.transfers = null;
    this.otherActivities = null;
    this.pending = null;
  }
}

export interface SummaryData {
  mainAssetSummary: MainAssetSummary;
  otherAssetBalanceList: OtherAssetBalance[];
  otherAssetsBalance: number;
}

export interface MainAssetSummary {
  openingBalance: number;
  closingBalance: number;
  donationsToMyFund: number;
  transfersMadeToThisFund: number;
  grantsToCharities: number;
  transfersMadeFromThisFund: number;
  otherActivities: number;
  availableBalance: number;
  assetInternalId: string;
  assetName: string;
  fundCash: boolean;
  totalAmountPendingTransactions: number;
}

export interface OtherAssetBalance {
  internalId: string;
  assetName: string;
  currency: string;
  currencySymbol: string;
  closingBalance: number;
  fundCash: false;
}
