import { Subscription } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from './_core/api/auth.service';
import { GlobalConfigApiService } from './_core/api/global-config.service';
import { Modals } from './_core/contants/modals';
import Urls from './_core/contants/urls';
import SharedMethodsHelpers from './_core/helpers/shared-methods.helpers';
import StorageHelper from './_core/helpers/storage.helper';
import { Fund } from './_core/models/fund';
import { AccessRoles } from './_core/models/keycloak';
import { User } from './_core/models/user';
import { FundService } from './_core/services/fund.service';
import { GlobalConfigService } from './_core/services/global-config.service';
import { KeycloakService } from './_core/services/keycloak.service';
import { ModalsService } from './_core/services/modals.service';
import { RolesService } from './_core/services/roles.service';
import { UserService } from './_core/services/user.service';
import { Configuration } from './app.constants';
import { ConfigSetting } from './pages/admin-welcome/global-config/global-config.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  currentUserSub: Subscription;
  lastPing?: Date = null;
  tokenInfo: any;
  pinAndPassword: any;
  loading: boolean;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private userService: UserService,
    private fundService: FundService,
    private authService: AuthService,
    private modalsService: ModalsService,
    private config: Configuration,
    private globalConfigApiService: GlobalConfigApiService,
    private globalConfigService: GlobalConfigService,
    private translate: TranslateService,
    private keycloakService: KeycloakService,
    private rolesService: RolesService,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.translate.use(environment.languages[0]);
    this.handleIdleTimeout();
    this.changeTheme();
    this.changeIcon();
    this.getGlobalConfiguration();
  }

  handleIdleTimeout(): void {
    this.idle.setIdle(600);
    this.idle.setTimeout(15);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      this.modalsService.closeModal();
      this.userService.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.modalsService.openModal(Modals.AUTO_LOGOUT);
    });

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.currentUserSub = this.userService.currentUser$.subscribe((user) =>
      user ? this.idle.watch() : this.idle.stop()
    );
  }

  getGlobalConfiguration(): void {
    this.loading = true;
    this.keycloakService.completedInitialization$
      .pipe(
        filter((completed) => completed || window.location.pathname.includes(`${Urls.PUBLIC}/${Urls.DONATE}`)),
        switchMap(() => this.globalConfigApiService.getConfiguration())
      )
      .subscribe({
        next: (res) => {
          this.globalConfigService.updateGlobalConfig(new ConfigSetting(res.response));
          this.handleRole();
          this.title.setTitle(res.response?.applicationTitle.trim() || 'Financial Management System');
        },
        error: () => {
          this.translate.get('TOASTER_BASIC_ERROR').subscribe((text: string) => {
            alert(text);
          });
          this.loading = false;
        },
      });
  }

  handleRole(): void {
    const isLoggedIn = this.keycloakService.getIsLoggedIn();

    if (isLoggedIn) {
      // this.tokenInfo = SharedMethodsHelpers.decodeToken();
      // const userName = StorageHelper.getUserName();
      // this.pinAndPassword = SharedMethodsHelpers.extractPinAndPasswordDetails(this.tokenInfo);
      if (this.rolesService.hasRoles([AccessRoles.USER, AccessRoles.ADMIN])) {
        this.getUserDetails();
      } else {
        // TEMPORARY USER ?
      }
      // switch (role) {
      //   case AccessRoles.USER:
      //   case AccessRoles.MASTER:
      //     this.getUserDetails();
      //     break;
      //   // case Role.TEMPORARY_USER:
      //   //   if (!this.pinAndPassword.goToChangePassword) {
      //   //     this.userService.setCurrentUser(this.pinAndPassword);
      //   //   } else {
      //   //     this.userService.setCurrentUser({
      //   //       email: userName,
      //   //       ...this.pinAndPassword.details,
      //   //     });
      //   //   }
      //   //   this.loading = false;
      //   //   break;
      //   case AccessRoles.ADMIN:
      //     this.userService.setCurrentUser(decodedToken.name as User);
      //     this.loading = false;
      //     break;
      //   default:
      //     break;
      // }
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.currentUserSub.unsubscribe();
  }

  getUserDetails(): void {
    this.authService.getUserDetails().subscribe(
      (user: User) => {
        //     const userInfo = { ...user, ...this.pinAndPassword };
        const { doesPinNumberExist } = user;
        this.userService.setCurrentUser(user);

        if (doesPinNumberExist) {
          this.handleFunds(user.funds);
        } else {
          this.router.navigate([`${Urls.APP}/${Urls.CHANGE_PASSWORD}`]);
        }
        this.loading = false;
      },
      () => {
        StorageHelper.killSession();
        this.loading = false;
        //     this.router.navigate([Urls.AUTH]);
        this.keycloakService.logout();
      }
    );
  }

  handleFunds(funds: Fund[]): void {
    if (!funds.length) {
      if (this.rolesService.hasRole(AccessRoles.ADMIN)) {
        if (this.router.url.includes(Urls.ADMIN)) return;
        this.router.navigate([`${Urls.APP}/${Urls.ADMIN}`]);
        return;
      }
      this.router.navigate([`${Urls.APP}/${Urls.NO_FUNDS}`]);
      return;
    }

    if (StorageHelper.getCurrentFund()) {
      this.fundService.fundChanged(StorageHelper.getCurrentFund());
    } else {
      this.fundService.fundChanged(funds[0]);
    }
  }

  changeTheme(): void {
    document.documentElement.style.setProperty('--main-color', this.config.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', this.config.secondaryColor);
    document.documentElement.style.setProperty('--ternary-color', this.config.ternaryColor);
  }

  changeIcon(): void {
    this.favIcon.href = this.config.favicon;
  }
}
