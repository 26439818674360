import FundTypes from '../contants/fund-types';

export default class StorageHelper {
  private static readonly tokenKey: string = 'jwtToken';
  private static readonly userNameKey: string = 'userName';
  private static readonly currentFund: string = 'currentFund';

  public static getToken() {
    return window.localStorage[this.tokenKey];
  }

  public static saveToken(token: string) {
    window.localStorage[this.tokenKey] = token;
  }

  public static killSession() {
    window.localStorage.removeItem(this.tokenKey);
    window.localStorage.removeItem(this.currentFund);
  }

  public static getUserName() {
    return window.localStorage[this.userNameKey];
  }

  public static saveUserName(email: string) {
    window.localStorage[this.userNameKey] = email;
  }

  public static removeUserName() {
    window.localStorage.removeItem(this.userNameKey);
  }

  public static saveCurrentFund(fund) {
    window.localStorage[this.currentFund] = JSON.stringify(fund);
  }

  public static getCurrentFund() {
    const existingFund = window.localStorage[this.currentFund] && window.localStorage[this.currentFund] !== 'undefined';
    return existingFund ? JSON.parse(window.localStorage[this.currentFund]) : null;
  }

  public static saveTargetFundType(fundType: FundTypes): void {
    window.sessionStorage.removeItem('targetFundType');
    window.sessionStorage.setItem('targetFundType', fundType);
  }

  public static getTargetFundType(): FundTypes | null {
    return window.sessionStorage.getItem('targetFundType') as FundTypes;
  }

  public static removeTargetFundType(): void {
    window.sessionStorage.removeItem('targetFundType');
  }
}
