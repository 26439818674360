import { NumberFormatSymbolOption, digitGroupingFormats, numberFormatSymbols } from './number-formats';

export class ConfigSetting {
  customLinkLabel: string;
  customLinkUrl: string;
  homeButtonUrl: string;
  defaultAnonymousContributionValue: boolean;
  dateFormat: string;
  decimalSymbol: string;
  digitGroupingSymbol: string;
  digitGrouping: string;
  numberFormat: {
    decimalSymbol: NumberFormatSymbolOption;
    digitGroupingSymbol: NumberFormatSymbolOption;
    digitGrouping: string;
  };
  announcement: string;
  recurrencePattern: string[];
  allowAnonymousContributionToOwnDAF: boolean;
  includeDonorInNewCharityRequest: boolean;
  applicationTitle: string;

  constructor(settingData: any) {
    this.applicationTitle = settingData.applicationTitle || 'Financial Management System';
    this.customLinkLabel = settingData.customLinkLabel;
    this.customLinkUrl = settingData.customLinkUrl;
    this.homeButtonUrl = settingData.homeButtonUrl;
    this.defaultAnonymousContributionValue = settingData.defaultAnonymousContributionValue;
    this.dateFormat = settingData.dateFormat || 'yyyy-MM-dd';
    this.numberFormat = {
      decimalSymbol: settingData.numberFormat?.decimalSymbol || numberFormatSymbols[0],
      digitGroupingSymbol: settingData.numberFormat?.digitGroupingSymbol || numberFormatSymbols[1],
      digitGrouping: settingData.numberFormat?.digitGrouping || digitGroupingFormats[1],
    };
    this.announcement = settingData.announcement;
    this.recurrencePattern = settingData.recurrencePattern || [
      'WEEKLY',
      'BI_WEEKLY',
      'MONTHLY',
      'QUARTERLY',
      'SEMI_ANNUALLY',
      'ANNUALLY',
    ];
    this.allowAnonymousContributionToOwnDAF = settingData.allowAnonymousContributionToOwnDAF || false;
    this.includeDonorInNewCharityRequest = settingData.includeDonorInNewCharityRequest || false;
  }
}
